<app-audio-recorder
  *ngIf="audioUrl()"
  [audioUrl]="audioUrl()"
  [isEditable]="isEditable"
  [translationKey]="translationKey"
  [language]="language"
  [isLoading]="isLoading()"
  (removeAudio)="onRemoveAudio()"
/>

<!-- Audio recorder -->
<app-audio-recorder
  *ngIf="isAudioRecorderEnabled() && !isSourceLanguage"
  [translationKey]="translationKey"
  [isEditable]="true"
  [language]="language"
  [isLoading]="isLoading()"
  [showError]="showError"
  (removeAudio)="onRemoveAudio()"
  (audioRecorded)="onAddAudio($event)"
  (resetAudio)="onResetAudio()"
/>
<!-- End audio recorder -->

<div class="editor__footer">
  <div class="flex justify-between items-center w-full flex-wrap gap-6">
    <div>
      <div *ngIf="isVoiceSettingChanged" class="mb-4 label-maj-xs--medium--red-100">
        {{ 'Voiceover.VoiceSettingsChanged' | translate }}
      </div>

      <div class="flex gap-2 flex-none">
        <div
          *ngIf="isEditable && (!LANGUAGE_CONFIG_MAP[language] || LANGUAGE_CONFIG_MAP[language].DeepL)"
          [matTooltipPosition]="'above'"
          [matTooltip]="'Icon.DeepL' | translate"
          class="flex relative icon-copy clickable clickable__wrapper btn__icon-rectangle btn__icon-rectangle--default"
          (click)="onTranslateText()"
        >
          <ng-container [ngSwitch]="translationStatus">
            <img
              *ngSwitchCase="TranslationStatusEnum.InProgress"
              src="assets/icons/png/icon-loading.png"
              alt="loading"
              class="clickable icon--md flex items-center infinite-spin"
            >

            <mat-icon
              *ngSwitchCase="TranslationStatusEnum.Error"
              class="clickable icon--md flex items-center"
              [svgIcon]="ICON_NAMES.TranslationError"
            ></mat-icon>

            <mat-icon
              *ngSwitchCase="TranslationStatusEnum.Success"
              class="clickable icon--md flex items-center"
              [svgIcon]="ICON_NAMES.TranslationSuccess"
            ></mat-icon>

            <mat-icon
              *ngSwitchDefault
              class="clickable icon--md flex items-center"
              [svgIcon]="ICON_NAMES.Translation"
            ></mat-icon>
          </ng-container>
        </div>

        <ng-container *ngIf="(isEditable && allowAudio && hasAudio && !audioUrl()) || isVoiceSettingChanged">
          <div
            *ngIf="!isVoiceSettingChanged"
            class="flex relative icon-copy btn__icon-rectangle btn__icon-rectangle--default"
            [class.clickable]="!disableAudio"
            [class.clickable__wrapper]="!disableAudio"
            [class.loading]="isLoading()"
            (click)="!disableAudio && onRecordAudioClicked()"
            [matTooltipPosition]="'above'"
            [matTooltip]="'Icon.RecordVoice' | translate"
          >
            <app-svg-icon icon="speech-record" width="24" height="24" />
          </div>

          <div
            *ngIf="!LANGUAGE_CONFIG_MAP[language] || LANGUAGE_CONFIG_MAP[language].ElevenLabs"
            class="flex relative icon-copy btn__icon-rectangle btn__icon-rectangle--default"
            [class.clickable]="!disableAudio"
            [class.clickable__wrapper]="!disableAudio"
            [class.loading]="isLoading()"
            (click)="!disableAudio && onAIAudioGenerationClicked()"
            [matTooltipPosition]="'above'"
            [matTooltip]="'Icon.RecordAI' | translate"
          >
            <app-svg-icon icon="speech-ai" width="24" height="24" />
          </div>
        </ng-container>
      </div>
    </div>

    @if (isEditable && allowAudio && (!LANGUAGE_CONFIG_MAP[language] || LANGUAGE_CONFIG_MAP[language].ElevenLabs)) {
      <app-editor-voice
        [isEditable]="isEditable"
        [voiceId]="projectKeyConfig?.config?.voice_id"
        (toggleVoiceSettings)="onToggleVoiceSettings()"
        (selectVoice)="onSelectVoice($event)"
      />
    }
  </div>
</div>

@if (isVoiceSettingsOpened) {
  <div class="px-8 pb-8">
    <app-podcast-voice-settings
      [voiceSettingsConfig]="projectKeyConfig?.config?.voice_settings"
      (updateVoiceSettings)="onVoiceSettingsUpdated($event)" />
  </div>
}
