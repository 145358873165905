import { Pipe, PipeTransform } from '@angular/core';
import { ISupportedLanguage } from '../../app/modules/home/dashboard/dashboard-project-item/dashboard-project-item.interface';
import { LANGUAGE_ASSETS } from '../constants/language-assets.constant';
@Pipe({
  name: 'languageNameByCode',
})
export class LanguageNameByCodePipe implements PipeTransform {
  transform(code: string): string {
    return LANGUAGE_ASSETS[code?.toLowerCase()]?.name || code;
  }
}
