import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

@Injectable({ providedIn: 'root' })
export class ProjectApiService {
  constructor(private readonly http: HttpClient) { }

  public getDrafts(id: string) {
    const url = `projects/${id}/drafts`
    return this.http.get(url)
  }

  public exportTranslations(id: string, body: any) {
    const url = `projects/${id}/translations/export`
    return this.http.post(url, body, { responseType: 'blob' })
  }

  public mergeToProd(id: string) {
    const url = `projects/${id}/merge-to-prod`
    return this.http.post(url, {})
  }
}
