import { PodcastKeyEnum } from './podcast.enum';
import { IPodcastVoiceSettings } from './podcast.interface';

export const PODCAST_KEY = '__Internal.Final'

export const PODCAST_KEY_CATEGORY = {
  [PodcastKeyEnum.TextToSpeech]: {
    icon: '/assets/icons/text-to-speech.svg',
    text: 'Podcast.Key.Category.TextToSpeech'
  },
  [PodcastKeyEnum.SpeechToSpeech]: {
    icon: '/assets/icons/speech-to-speech.svg',
    text: 'Podcast.Key.Category.SpeechToSpeech'
  },
}

export const PODCAST_VOICE_DEFAULT_SETTINGS: IPodcastVoiceSettings = {
  stability: 7,
  similarity_boost: 15,
  style: 7,
  use_speaker_boost: true,
}

export const PODCAST_SUPPORTED_LANGUAGES: string[] = [
  'ar','bg','cs','da','de-DE_AT','el','en-AUS_NZ','en-CA','en-SG','en','en-US','es-AR','es-ES','es-MX','fi','he','hr','hu','it', 'ja',
  'ko','ms','nb','nl','pl','ro','ru','sk','sl','sr','sv','th','tk','uk','vi','fr','id','pt-BR','pt-PT','zh-CN','zh-HK','zh-SG','zh-TW'
];
