import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslationRowComponent } from './translation-row.component';
import { TranslationRowRoutingModule } from './translation-row-routing.module';
import { KeyNotesModule } from './key-notes/key-notes.module';
import { PipesModule } from '@app/shared';
import { SlateTextEditorComponent } from './text-editor-slate/text-editor.component';

@NgModule({
  declarations: [TranslationRowComponent],
  imports: [CommonModule, TranslationRowRoutingModule, KeyNotesModule, PipesModule, SlateTextEditorComponent],
  exports: [TranslationRowComponent],
})
export class TranslationRowModule {}
