import { Directive, ViewChild, } from '@angular/core';
import { Editor, Transforms } from 'slate';
import { MarkTypes } from './text-editor.enum';
import { TextMarkComponent } from './text-mark.component';
import { BaseText } from 'slate/dist/interfaces/text';
import { buildEditorSlateSchema } from './text-editor.util';
import { AngularEditor, SlateEditable } from 'slate-angular';
import { BaseComponent } from '@app/shared';

@Directive()
export abstract class TextEditorToolbarDirective extends BaseComponent {
  public retainContent: string;
  public editorValue: any;
  public abstract editor: AngularEditor;

  public isSlateEditorEnabled = false;

  @ViewChild('slateEditor') slateEditor: SlateEditable;

  protected isPasteEventTriggered = false;

  public onPaste = () => {
    this.isPasteEventTriggered = true;
  }

  public focusSlateEditor() {
    setTimeout(() => {

      this.slateEditor.elementRef.nativeElement.click();
      AngularEditor.focus(this.editor);
      const noOfLines = this.editor.children.length;
      if (isNaN(noOfLines)) {
        return;
      }
      const lastParagraph = (this.editor.children[noOfLines - 1] as any);
      const noOfTextsInLastLine = lastParagraph?.children?.length;
      if (isNaN(noOfTextsInLastLine)) {
        return;
      }

      const lastTextLength = lastParagraph.children[noOfTextsInLastLine - 1]?.text?.length || 0;

      Transforms.select(this.editor, { offset: lastTextLength, path: [noOfLines - 1, noOfTextsInLastLine - 1] });
    });
  }

  public rebuildEditorValueAfterPaste() {
    this.editorValue = buildEditorSlateSchema(this.retainContent);
    this.isPasteEventTriggered = false;
  }

  public isMarkActive = format => {
    const marks = Editor.marks(this.editor);
    return marks ? marks[format] === true : false;
  };

  public toggleMark = format => {
    const isActive = this.isMarkActive(format);

    if (isActive) {
      Editor.removeMark(this.editor, format);
    } else {
      Editor.addMark(this.editor, format, true);
    }
  };

  public renderText = (text: BaseText) => {
    if (text[MarkTypes.bold] || text[MarkTypes.italic] || text[MarkTypes.underline]) {
      return TextMarkComponent;
    }

    return null;
  };

  public toolbarItems = [
    {
      format: MarkTypes.bold,
      icon: 'format_bold',
      active: this.isMarkActive,
      action: this.toggleMark
    },
    {
      format: MarkTypes.italic,
      icon: 'format_italic',
      active: this.isMarkActive,
      action: this.toggleMark
    },
    {
      format: MarkTypes.underline,
      icon: 'format_underlined',
      active: this.isMarkActive,
      action: this.toggleMark
    },
  ];
}
