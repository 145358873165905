import { ChangeDetectorRef, Directive, EventEmitter, inject, Input, Output } from '@angular/core';
import { PodcastKeyService } from '../podcast-key.service';
import { PodcastService } from '../podcast.service';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent, LocalSpinnerService } from '@app/shared';
import { IPodcast, IPodcastKeyConfig } from '../podcast.interface';
import { BehaviorSubject, finalize, Subscription } from 'rxjs';
import { PodcastSourceKeyService } from '../podcast-source-key.service';
import { PodcastEnum } from '../podcast.enum';

@Directive({
  selector: '[appBasePodcast]',
  standalone: true
})
export class BasePodcastDirective extends BaseComponent {

  public readonly PodcastEnum = PodcastEnum;

  protected _language: string
  protected _mainLanguage: string
  protected _podcastKeyService: PodcastKeyService;
  protected _podcastSourceKeyService: PodcastSourceKeyService;
  protected _podcastService: PodcastService;
  protected _localSpinnerService: LocalSpinnerService;
  protected _activatedRoute: ActivatedRoute;
  protected _changeDetectorRef: ChangeDetectorRef;
  protected _keyConfig: IPodcastKeyConfig;
  protected _sourceKeyConfig: IPodcastKeyConfig;

  public podcast$: BehaviorSubject<IPodcast>;

  @Input() isGeneratingSpeech: boolean;
  @Input() isSourceKey: boolean;

  @Output() generateSpeech = new EventEmitter<void>();

  constructor() {
    super();
    this._podcastKeyService = inject(PodcastKeyService);
    this._podcastSourceKeyService = inject(PodcastSourceKeyService);
    this._podcastService = inject(PodcastService);
    this._localSpinnerService = inject(LocalSpinnerService);
    this._activatedRoute = inject(ActivatedRoute);
    this._changeDetectorRef = inject(ChangeDetectorRef);
    this._language = this._activatedRoute.snapshot.params.language;
    this._mainLanguage = this._activatedRoute.snapshot.queryParams.mainLanguage;
    this.podcast$ = this._podcastService.podcast$;
  }

  public onDeleteAudio(name: string, language: string): void {
    const requestPayload: Partial<IPodcastKeyConfig> = { language, name, url: undefined };
    const spinnerId = `CLEAR_SPEECH-${language}-${name}`;
    this._localSpinnerService.registerComponent(spinnerId);
    this._localSpinnerService.startLocalSpinner(spinnerId);
    this._podcastKeyService.clearSpeech(this._podcastService.podcast$.value.id, requestPayload)
      .pipe(finalize(() => this._localSpinnerService.stopLocalSpinner(spinnerId)))
      .subscribe(() => {
        this._podcastKeyService.updateKeyConfigState({
          ...this._keyConfig,
          ...requestPayload
        });
      });
  }

}
