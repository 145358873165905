import { environment } from 'src/environments/environment';
import { IntegrationModeEnum } from '../enums';

/* tslint:disable:max-line-length */
export class CommonConstant {
  public static readonly CHANGELOG_PAGE_SIZE = 40;
  public static readonly INTEGRATION_MODES = { [IntegrationModeEnum.CSV_202]: 'CSV_202' };
  public static readonly SUPPORTED_LANGUAGES = ['en-uk'];
  public static readonly SUPPORTED_THEMES = ['light', 'dark'];
  public static readonly SUPPORTED_INPUT_FILE = ['.xlsx', '.xls', '.csv'];
  public static readonly DEFAULT_CHANEL_SIGN_IN_URL_PRO = `${environment?.ssoSignIn?.url}?response_type=${environment?.ssoSignIn?.responseType}&client_id=${environment?.ssoSignIn?.clientId}&redirect_uri=${environment?.ssoSignIn?.redirectUrl}`;
  public static readonly DEFAULT_CHANEL_SIGN_IN_URL_STAGING = 'https://login.microsoftonline.com/e958058b-1cea-44d4-a8da-8bf0b24bae08/oauth2/v2.0/authorize?client_id=f8b527bb-38d4-4e1e-8d0d-e73442f95b7b&scope=openid&response_type=code';
  // Filter
  public static readonly DEFAULT_SORT_KEY = 'createdAt';
  // Paging
  public static readonly PAGING_PAGE_SIZE = 50;
  public static readonly PAGING_PAGE_INDEX = 1;
  public static readonly PAGING_OPTIONS = [10, 25, 50];
  public static readonly PROJECT_PAGING_PAGE_SIZE = 20;
  public static readonly GROUP_PAGING_PAGE_SIZE = 20;
  public static readonly MAIN_LANGUAGE = 'en-uk';
  //
  public static readonly TEXT_EDITOR_MAX_LENGTH = 5000;
  public static readonly USER_TOKEN = 'USER_TOKEN';
  public static readonly LANGUAGE_TOKEN = 'LANGUAGE_TOKEN';
  public static readonly THEME_TOKEN = 'THEME_TOKEN';
  public static readonly APP_VALIDATION_TOKEN = 'APP_VALIDATION_TOKEN';
  public static readonly SUPER_ADMIN_ROLE = 'SUPER_ADMIN';
  public static readonly FIREBASE_USER_CREDENTIAL = 'FIREBASE_USER_CREDENTIAL';
  public static readonly DEFAULT_LANGUAGE = 'en-uk';
  public static readonly DEFAULT_THEME = 'light';
  public static readonly REGEX_STRONG_PASSWORD = new RegExp(
    `^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()_+{}|:">?<,./';\\[\\]=-]).{8,64}$`,
  );
  public static readonly PHONE_NUMBER_REGEX = /(\+*\d{1,})*([ |\(])*(\d{3})[^\d]*(\d{3})[^\d]*(\d{4})/;
  // Input
  public static readonly INPUT_DEBOUNCE_INTERVAL = 500;
  public static readonly SEARCH_DEBOUNCE_INTERVAL = 500;
  public static readonly APP_LOGO_MEDIUM = 'assets/images/logo-medium.svg';
  public static readonly AUTH_BACKGROUND = 'assets/images/auth-background.svg';

  // SnackBar Config
  public static readonly DEFAULT_SNACKBAR_CONFIG_DURATION = 5000;
  public static readonly DEFAULT_SNACKBAR_CONFIG = {
    duration: CommonConstant.DEFAULT_SNACKBAR_CONFIG_DURATION,
    panelClass: ['default__snack-bar'],
  };
  public static readonly SUCCESS_SNACKBAR_CONFIG = {
    duration: CommonConstant.DEFAULT_SNACKBAR_CONFIG_DURATION,
    panelClass: ['success__snack-bar'],
  };
  public static readonly FAILURE_SNACKBAR_CONFIG = {
    duration: CommonConstant.DEFAULT_SNACKBAR_CONFIG_DURATION,
    panelClass: ['failure__snack-bar'],
  };

  // Field Config
  public static readonly FIELD_CONTROL_MAX_LENGTH = {
    email: 320,
    password: 64,
  };

  public static readonly FIELD_CONTROL_MIN_LENGTH = {
    email: 3,
    password: 8,
  };

  public static readonly FILE_FORMATS = ['image/png', 'image/jpeg'];

  public static readonly URL_REGEX =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

  public static readonly MAX_SIZE_FILE_UPLOAD = 10000000;
  public static readonly MAX_SIZE_VIDEO_UPLOAD = 50000000;
}
