<ng-container *ngIf="!!voiceSettings">
  <app-eleven-labs-setting
    [settingValue]="voiceSettings.stability"
    settingName="Podcast.VoiceSettings.Stability.Name"
    labelMin="Podcast.VoiceSettings.Stability.Start"
    labelMax="Podcast.VoiceSettings.Stability.End"
    (settingValueChanged)="onStabilityUpdate($event)"
  />

  <div class="mt-6">
    <app-eleven-labs-setting
      [settingValue]="voiceSettings.similarity_boost"
      settingName="Podcast.VoiceSettings.Similarity.Name"
      labelMin="Podcast.VoiceSettings.Similarity.Start"
      labelMax="Podcast.VoiceSettings.Similarity.End"
      (settingValueChanged)="onSimilarityBoostUpdate($event)"
    />
  </div>

  <div class="mt-6">
    <app-eleven-labs-setting
      [settingValue]="voiceSettings.style"
      settingName="Podcast.VoiceSettings.StyleExaggeration.Name"
      labelMin="Podcast.VoiceSettings.StyleExaggeration.Start"
      labelMax="Podcast.VoiceSettings.StyleExaggeration.End"
      (settingValueChanged)="onStyleUpdated($event)"
    />
  </div>
</ng-container>
