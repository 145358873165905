import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KeyNotesComponent } from './key-notes.component';
import { InputModule } from '../../../../../../../shared';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { TextAreaWrapperComponent } from './text-area-wrapper/text-area-wrapper.component';
import { DirectiveModule } from '../../../../../../../shared/directives/directive.module';

@NgModule({
  declarations: [KeyNotesComponent, TextAreaWrapperComponent],
  exports: [KeyNotesComponent],
  imports: [DirectiveModule, CommonModule, InputModule, MatIconModule, FormsModule, TranslateModule, MatInputModule],
})
export class KeyNotesModule {}
