import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PodcastService } from '../../podcast.service';
import { IPodcastVoiceId } from '../../podcast.interface';
import { DropdownComponent } from '../../../../theme/components/theme-details/passport/dropdown/dropdown.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-podcast-voice',
  standalone: true,
  imports: [
    DropdownComponent,
    TranslateModule,
  ],
  templateUrl: './podcast-voice.component.html',
  styleUrl: './podcast-voice.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PodcastVoiceComponent {
  public voices: IPodcastVoiceId[];

  public defaultOption: IPodcastVoiceId;

  @Input() set defaultValue(value: string) {
    if (!value) {
      return;
    }

    this.defaultOption = (this.voices || []).find(voice => voice.voice_id === value);
  }

  @Input() isDisabled: boolean;
  @Input() podcastVoiceControl: FormControl<string>;

  @Output() selectVoice = new EventEmitter<string>();

  constructor(private _podcastService: PodcastService) {
    this.voices = this._podcastService.voices$.value;
  }

  onOptionSelected(value: IPodcastVoiceId) {
    const selectedVoice = value?.voice_id || null;
    this.podcastVoiceControl?.setValue(selectedVoice);
    this.selectVoice.emit(selectedVoice)
  }
}
