<div class="w-full">
  <div class="text--long--sm--semibold">
    {{ settingName | translate }}
  </div>

  <div class="mt-3 flex justify-between items-center label-maj-xs--medium uppercase">
    <span>{{ labelMin | translate }}</span>
    <span>{{ labelMax | translate }}</span>
  </div>

  <input #seekbar type="range" class="flex-1 seekbar w-full outline-0 overflow-visible" [(ngModel)]="settingValue" max="100">
</div>
