import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';
import { IApiResponse } from '@app/core';
import { BaseApiService } from '../../../core/services/base-api.service';
import { IPodcastKeyConfig } from './podcast.interface';

@Injectable({ providedIn: 'root' })
export class PodcastSourceKeyService extends BaseApiService<IPodcastKeyConfig> {
  protected apiUrl = 'podcasts/:id/keys/config';

  private _podcastKeys$: BehaviorSubject<IPodcastKeyConfig[]> = new BehaviorSubject<IPodcastKeyConfig[]>([]);

  public get podcastKeys$() {
    return this._podcastKeys$
  }

  public findKeyConfig(language: string, name: string) {
    return this._podcastKeys$.value.find((key => key.name === name && key.language === language));
  }

  public getPodcastKeyConfigs(podcastId: string, params?: HttpParams): Observable<IApiResponse<IPodcastKeyConfig>> {
    return super.getList(params, this._getUrl(podcastId)).pipe(tap((response: IApiResponse<IPodcastKeyConfig>) => {
      if (response.items) {
        this._podcastKeys$.next(response.items);
      }
    }));
  }

  private _getUrl(podcastId: string, url: string = this.apiUrl) {
    return url.replace(':id', podcastId);
  }
}
