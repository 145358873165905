import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IApiResponse } from '@app/core';
import { BehaviorSubject, Observable, of, switchMap, tap } from 'rxjs';
import { BaseApiService } from '../../../core/services/base-api.service';
import { PODCAST_VOICE_DEFAULT_SETTINGS } from './podcast.const';
import { IPodcast, IPodcastKey, IPodcastKeyConfig, IPodcastVoiceConfig } from './podcast.interface';

@Injectable({ providedIn: 'root' })
export class PodcastKeyService extends BaseApiService<IPodcastKeyConfig> {
  protected apiUrl = 'podcasts/:id/keys/config';

  private _podcastKeys$: BehaviorSubject<IPodcastKeyConfig[]> = new BehaviorSubject<IPodcastKeyConfig[]>([]);
  private _podcastKey$: BehaviorSubject<IPodcastKeyConfig> = new BehaviorSubject<IPodcastKeyConfig>(null);

  public get podcastKeys$() {
    return this._podcastKeys$
  }

  public get podcastKey$() {
    return this._podcastKey$
  }

  public setPodcastLanguageKey(podcast: IPodcastKeyConfig) {
    this.podcastKey$.next(podcast);
  }

  public findConfig(name: string, language: string) {
    return this._podcastKeys$.value.find(podcastKey => podcastKey.name === name && podcastKey.language === language) || {
      language, name
    } as IPodcastKeyConfig;
  }

  public createKey(podcastId: string, payload: Partial<IPodcastKey>) {
    return this.create(payload, this._getUrl(podcastId, `podcasts/:id/keys`));
  }

  public updateKeyConfig(podcastId: string, payload: Partial<IPodcastKeyConfig>): Observable<IPodcastKeyConfig> {
    console.log(podcastId, payload);
    return this.patch(payload, this._getUrl(podcastId, this.apiUrl)).pipe(tap((response) => {
      if (response) {
        this.updateKeyConfigState(response);
      }
    }));
  }

  public setDefaultConfig(podcast: IPodcast, currentKeyConfig: Partial<IPodcastKeyConfig>) {
    if (currentKeyConfig?.config?.config && currentKeyConfig.config.voice_id) {
      return of(null);
    }

    const voiceSettings = currentKeyConfig?.config?.config
      || (currentKeyConfig?.config?.voice_settings || (podcast.config?.config || PODCAST_VOICE_DEFAULT_SETTINGS));

    const defaultConfig: Partial<IPodcastVoiceConfig> = {
      voice_id: currentKeyConfig?.config?.voice_id || podcast.config?.voice_id,
      config: voiceSettings
    }

    return this.updateKeyConfig(podcast.id, { language: currentKeyConfig.language, name: currentKeyConfig.name, config: defaultConfig })
  }

  public generateSpeech(podcastId: string, payload: Partial<IPodcastKeyConfig>) {
    return this.patch(payload, this._getUrl(podcastId, `${this.apiUrl}/generate`)).pipe(tap((response) => {
      if (response) {
        this.updateKeyConfigState(response);
      }
    }));
  }

  public generateAllSpeeches(podcastId: string, language: string) {
    return this.create({ language }, this._getUrl(podcastId, `podcasts/:id/keys/generate`))
      .pipe(switchMap(() => {
        const httpParams = new HttpParams().appendAll({ language: language });
        return this.getPodcastKeyConfigs(podcastId, httpParams);
      }));
  }

  public translateAllKeys(podcastId: string, language: string) {
    return this.create({ language }, this._getUrl(podcastId, `podcasts/:id/keys/translate`))
      .pipe(switchMap(() => {
        const httpParams = new HttpParams().appendAll({ language: language });
        return this.getPodcastKeyConfigs(podcastId, httpParams);
      }));
  }

  public clearSpeech(podcastId: string, payload: Partial<IPodcastKeyConfig>) {
    return this.patch(payload, this._getUrl(podcastId, `${this.apiUrl}/clear`));
  }

  public uploadAudio(podcastId: string, key: string, language: string, file: Blob, fileName: string, isGenerated: boolean = false) {
    const payload = new FormData();
    payload.append('language', language);
    payload.append('name', key);
    payload.append('file', file, fileName);

    if (isGenerated) {
      payload.append('isGenerated', '1');
    }

    return this.patch(payload, this._getUrl(podcastId, `${this.apiUrl}/upload`)).pipe(tap((response) => {
      if (response) {
        this.updateKeyConfigState(response);
      }
    }));
  }

  public uploadVideo(podcastId: string, file: Blob, fileName: string) {
    const payload = new FormData();
    payload.append('file', file, fileName);

    return this.patch(payload, this._getUrl(podcastId, `${this.apiUrl}/upload-video`)).pipe(tap((response) => {
      if (response) {
        this.updateKeyConfigState(response);
      }
    }));
  }

  public getPodcastKeyConfigs(podcastId: string, params?: HttpParams): Observable<IApiResponse<IPodcastKeyConfig>> {
    return super.getList(params, this._getUrl(podcastId)).pipe(tap((response: IApiResponse<IPodcastKeyConfig>) => {
      if (response.items) {
        this._podcastKeys$.next(response.items);
      }
    }));
  }

  public generatePodcast(podcastId: string, language: string) {
    return this._httpClient.post<IPodcastKeyConfig>(this._getUrl(podcastId, `podcasts/:id/generate`), { language }).pipe(tap((response) => {
      if (response) {
        this.updateKeyConfigState(response);
      }
    }));
  }

  public updateKeyConfigState(config: IPodcastKeyConfig) {
    const allConfigs = this._podcastKeys$.value;
    const currentConfig = allConfigs.find(key => key.name === config.name && key.language === config.language);

    if (!currentConfig) {
      this._podcastKeys$.next([...allConfigs, config]);
    } else {
      const newConfigs = allConfigs.map(oldConfig => {
        if (oldConfig.language !== config.language || oldConfig.name !== config.name) {
          return oldConfig;
        } else {
          return config;
        }
      });
      this._podcastKeys$.next(newConfigs);
    }
  }

  private _getUrl(podcastId: string, url: string = this.apiUrl) {
    return url.replace(':id', podcastId);
  }
}
