import { NgModule } from '@angular/core';
import { InputErrorPipe } from './input-error.pipe';
import { ReplacePipe } from './replace.pipe';
import { TruncatePipe } from './truncate.pipe';
import { VariablePipe } from './variable.pipe';
import { GetCountryNamePipe } from './get-country-name.pipe';
import { FlagByCodePipe } from './flag-by-code.pipe';
import { LanguageNameByCodePipe } from './language-name-by-code.pipe';
import { ExcludeOptionPipe } from './exclude-option.pipe';
import { HeaderClassNameByDraftStatus } from './header-class-name-by-draft-status.pipe';
import { PrefixSectionPipe } from './prefix-section.pipe';
import { CustomFormatDatePipe } from './custom-format-date.pipe';
import { RemoveSectionDomainPipe } from './remove-section-domain.pipe';

const pipes = [
  InputErrorPipe,
  ReplacePipe,
  TruncatePipe,
  VariablePipe,
  GetCountryNamePipe,
  FlagByCodePipe,
  LanguageNameByCodePipe,
  ExcludeOptionPipe,
  HeaderClassNameByDraftStatus,
  PrefixSectionPipe,
  CustomFormatDatePipe,
  RemoveSectionDomainPipe,
];
@NgModule({
  declarations: [...pipes],
  exports: [...pipes],
})
export class PipesModule {}
