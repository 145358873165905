import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';
import { IApiResponse } from '@app/core';
import { BaseApiService } from '../../../core/services/base-api.service';
import { IPodcast, IPodcastVoiceId, IPodcastVoicesResponse } from './podcast.interface';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PodcastService extends BaseApiService<IPodcast> {
  protected apiUrl = 'podcasts';

  private _podcasts$: BehaviorSubject<IPodcast[]> = new BehaviorSubject<IPodcast[]>([]);
  private _podcast$: BehaviorSubject<IPodcast> = new BehaviorSubject<IPodcast>(null);

  private _voices$: BehaviorSubject<IPodcastVoiceId[]> = new BehaviorSubject<IPodcastVoiceId[]>([]);

  public get podcast$() {
    return this._podcast$
  }

  public get voices$() {
    return this._voices$
  }

  public clear() {
    this._podcast$.next(null);
    this._podcasts$.next([]);
  }

  public override getDetailsById(id: string, payload?: HttpParams, showToast?: boolean): Observable<IPodcast> {
    return super.getDetailsById(id, payload, showToast).pipe(tap((response: IPodcast) => {
      if (response) {
        this._podcast$.next(response);
      }
    }));
  }

  public getVoices() {
    if (this._voices$.value.length > 0) {
      return of(this._voices$.value);
    }
    return this._httpClient.get<IPodcastVoicesResponse>(`${this.apiUrl}/voices`).pipe(
      map((response: IPodcastVoicesResponse) => response?.voices),
      tap((voices) => {
        if (voices) {
          this._voices$.next(voices.sort((a,b) => a.name.localeCompare(b.name)).map(voice => ({
            ...voice,
            id: voice.voice_id,
          })));
        }
      }),
    );
  }

  public override getList(params?: HttpParams): Observable<IApiResponse<IPodcast>> {
    return super.getList(params).pipe(tap((response: IApiResponse<IPodcast>) => {
      if (response.items) {
        this._podcasts$.next(response.items);
      }
    }));
  }

  public override patch(requestPayload: Partial<IPodcast>, podcastId: string, showToast?: boolean): Observable<IPodcast> {
    return super.patch(requestPayload, `${this.apiUrl}/${podcastId}`, showToast).pipe(
      tap(() => {
        const updatedPodcast = {...this._podcast$.value, ...requestPayload};
        if (podcastId === this._podcast$.value?.id) {
          this._podcast$.next(updatedPodcast);
        }

        if (this._podcasts$.value?.length > 0) {
          this.updatePodcastsState(updatedPodcast)
        }
      })
    );
  }

  public updatePodcastsState(podcast: IPodcast) {
    const allPodcasts = this._podcasts$.value;
    const currentConfig = allPodcasts.find(key => key.id === podcast.id);

    if (!currentConfig) {
      this._podcasts$.next([...allPodcasts, podcast]);
    } else {
      const newPodcasts = allPodcasts.map(oldPodcast => {
        if (oldPodcast.id !== podcast.id) {
          return oldPodcast;
        } else {
          return podcast;
        }
      });
      this._podcasts$.next(newPodcasts);
    }
  }

  public deletePodcast(podcastId: string) {
    return super.create({}, `podcasts/${podcastId}/delete`, true);
  }
}
