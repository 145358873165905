import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IPodcastVoiceSettings } from '../../../../podcast.interface';
import { PODCAST_VOICE_DEFAULT_SETTINGS } from '../../../../podcast.const';
import { BasePodcastDirective } from '../../../base-podcast.directive';
import { ElevenLabsSettingComponent } from './eleven-labs-setting/eleven-labs-setting.component';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-podcast-voice-settings',
  standalone: true,
  imports: [
    ElevenLabsSettingComponent,
    NgIf,
  ],
  templateUrl: './podcast-voice-settings.component.html',
  styleUrl: './podcast-voice-settings.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PodcastVoiceSettingsComponent extends BasePodcastDirective implements OnDestroy, OnInit {
  public voiceSettings: IPodcastVoiceSettings;

  @Input() voiceSettingsConfig: IPodcastVoiceSettings

  @Output() updateVoiceSettings = new EventEmitter<IPodcastVoiceSettings>();

  private _configUpdateEvents = new Subject<IPodcastVoiceSettings>();
  private _subscription: Subscription;

  ngOnInit() {
    if (this.voiceSettingsConfig) {
      this.voiceSettings = { ...this.voiceSettingsConfig };
    } else if (this._podcastService.podcast$.value?.config?.config) {
      this.voiceSettings = { ...this._podcastService.podcast$.value.config.config };
    } else {
      this.voiceSettings = {...PODCAST_VOICE_DEFAULT_SETTINGS}
    }
    this._subscription = this._configUpdateEvents.pipe(debounceTime(500)).subscribe((config) => this.updateVoiceSettings.emit(config));
  }

  ngOnDestroy() {
    this._subscription?.unsubscribe();
  }

  public onStabilityUpdate(configValue: number): void {
    const config: IPodcastVoiceSettings = {
      ...this.voiceSettings,
      stability: configValue
    };

    this.updateVoiceSettings.emit(config);
  }

  public onSimilarityBoostUpdate(configValue: number): void {
    const config: IPodcastVoiceSettings = {
      ...this.voiceSettings,
      similarity_boost: configValue
    };

    this.updateVoiceSettings.emit(config);
  }

  public onStyleUpdated(configValue: number): void {
    const config: IPodcastVoiceSettings = {
      ...this.voiceSettings,
      style: configValue
    };

    this.updateVoiceSettings.emit(config);
  }
}
