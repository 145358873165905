import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, finalize, map, Observable, of, switchMap, tap } from 'rxjs';
import { ExportModeEnum } from '../../../shared';
import { ProjectApiService } from '../../apis/project-api.service';
import { IProject } from '../../modules/home';
import { TranslationErrorSeverityEnum } from '../../modules/language-detail/language-detail.enum';
import { INote } from '../../modules/language-detail/translation-tool/translate-to/translation-row/text-editor-slate/text-editor.interface';
import { IApiResponse } from '../interface';
import { BaseApiService } from './base-api.service';

@Injectable()
export class ProjectsService extends BaseApiService<IProject> {
  apiUrl = 'projects';

  private _projectMap = new Map<string, IApiResponse<IProject>>;
  private _project$: BehaviorSubject<IProject> = new BehaviorSubject<IProject>(null);

  public get project$() {
    return this._project$;
  }

  constructor(private readonly projectApiService: ProjectApiService) {
    super();
  }

  public updateProjectById(id: string, requestPayload: Partial<IProject>) {
    const url = `${this.apiUrl}/${id}`;
    return this.put(requestPayload, url).pipe(finalize(() => this.isListLoading$.next(false)));
  }

  public getProjectList(payload?: HttpParams): Observable<IApiResponse<IProject>> {
    const paramsString = payload?.keys().map(key => payload.get(key)).join('_') || 'noParam';
    if (this._projectMap.has(paramsString)) {
      this.isListLoading$.next(false);
      return of(this._projectMap.get(paramsString));
    }
    this.isListLoading$.next(true);
    return this._httpClient
      .get<IApiResponse<IProject>>(this.apiUrl, { params: payload })
      .pipe(
        tap(response => {
          this._projectMap.set(paramsString, response);
        }),
        finalize(() => this.isListLoading$.next(false))
      );
  }

  public getTranslationByProjectId(projectId: string, language: string) {
    const url = `${this.apiUrl}/${projectId}/translations`;
    const httpParams = new HttpParams().set('language', language);
    return this.getDetailsByCustomUrl(url, httpParams);
  }

  public getLanguageDetail(projectId: string, draftId: string, language: string) {
    return of(draftId).pipe(
      switchMap((id) => {
        return id
          ? of(id)
          : this.create({ projectId, language }, `projects/${projectId}/drafts`).pipe(
            map((v) => v.id),
          )
      }),
      switchMap((id) => this.getDetailsByCustomUrl(`drafts/${id}`)),
      tap((response) => {
        this._project$.next(response);
      }),
    )
  }

  public exportTranslations(
    projectId: string,
    draftId: string,
    sourceLanguageCode: string,
    exportMode: ExportModeEnum,
  ): Observable<Blob> {
    const url = `${this.apiUrl}/${projectId}/translations/export`;
    let payload = new HttpParams().set('sourceLanguage', sourceLanguageCode);
    payload = payload.append('draftId', draftId);
    !!exportMode && (payload = payload.append('mode', exportMode));
    return this._httpClient.post(url, payload, { responseType: 'blob' });
  }

  public importTranslations(projectId: string, draftId: string, file: File, isApplyAutoFix?: boolean) {
    const url = `${this.apiUrl}/${projectId}/translations/import`;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('draftId', draftId);
    isApplyAutoFix && formData.append('isApplyAutoFix', String(isApplyAutoFix));
    return this._httpClient.post(url, formData);
  }

  public getSupportedLanguages(projectId: string) {
    const withLanguageKPI = new HttpParams().set('withLanguageKPI', false);
    return this.getDetailsById(projectId, withLanguageKPI);
  }

  public importSource(projectId: string, file: File) {
    const url = `${this.apiUrl}/${projectId}/import-source`;
    const formData = new FormData();
    formData.append('file', file);
    return this._httpClient.post(url, formData);
  }

  public fixImportSource(projectId: string) {
    const url = `${this.apiUrl}/${projectId}/fix-import-source`;
    return this._httpClient.post(url, {});
  }

  public updateTranslation(draftId: string, payload: object) {
    const url = `drafts/${draftId}/translations`;
    return this.patch(payload, url);
  }

  public updateLanguageStatus(draftId: string, status: string) {
    const url = `drafts/${draftId}/status`;
    return this.put({ status: status }, url);
  }

  public fixTheTranslation(draftId: string, action: string, isFixDraftKeyStatus?: boolean) {
    const url = `drafts/${draftId}/fix-translation`;
    return this.create({ action, isFixDraftKeyStatus: !!isFixDraftKeyStatus }, url);
  }

  public liteFixTheTranslation(draftId: string, action: string) {
    return this.fixTheTranslation(draftId, action);
  }

  public fullFixTheTranslation(draftId: string, action: string, status: string, isFixDraftKeyStatus?: boolean) {
    return this.fixTheTranslation(draftId, action, isFixDraftKeyStatus).pipe(
      switchMap((response) => {
        return this.updateLanguageStatus(draftId, status);
      }),
      catchError((err) => {
        // Handle for case of translation info error type from BE
        if (
          Array.isArray(err?.error) &&
          err?.error?.length > 0 &&
          err?.error?.every((item) => item?.type === TranslationErrorSeverityEnum.INFO)
        ) {
          return err.error;
        }
        throw err;
      }),
    );
  }

  public createNote(projectId: string, payLoad: INote) {
    const url = `${this.apiUrl}/${projectId}/notes`;
    return this.create(payLoad, url);
  }

  public updateNote(note: INote) {
    const url = `notes/${note.id}`;
    return this.put(note, url);
  }

  public deleteNote(noteId: string) {
    return this._httpClient.delete(`notes/${noteId}`);
  }

  public projectChangelog(projectId: string, pageSize: number, pageIndex: number) {
    return this._httpClient.get(`${this.apiUrl}/${projectId}/translation-history`, {
      params: { pageSize, pageIndex },
    });
  }

  public mergeToProd(id: string) {
    return this.projectApiService.mergeToProd(id)
  }
}
