import { AccountActionType } from 'src/app/modules/account-management/account.enum';
import { IProject } from '../../app/modules/home';
import { UserRoleEnum, UserStatusEnum } from '../enums';
import { IBaseModel } from './base.interface';

export enum UserCategoryEnum {
  MakeUp = 'MAKEUP',
  Skincare = 'SKINCARE',
  Fragrance = 'FRAGRANCE',
  Retail = 'RETAIL',
  LAndD = 'L&D',
}

export const UserCategoryTranslation = {
  [UserCategoryEnum.MakeUp]: 'Podcast.Category.MakeUp',
  [UserCategoryEnum.Skincare]: 'Podcast.Category.Skincare',
  [UserCategoryEnum.Fragrance]: 'Podcast.Category.Fragance',
  [UserCategoryEnum.Retail]: 'Podcast.Category.Retail',
  [UserCategoryEnum.LAndD]: 'Podcast.Category.LAndD',
}

export enum UserMarketEnum {
  Global = 'GLOBAL',
  Europe = 'EUROPE',
  Apac = 'APAC',
  US = 'US',
  China = 'CHINA',
  Japan = 'JAPAN',
  UR = 'UK REGION',
  SMR = 'SWISS MARKETS REGION',
}


export interface TranslationUser {
  id: string;
  role: UserRoleEnum;
  allowLanguages: string[];
  categories: UserCategoryEnum[];
  markets: UserMarketEnum[];
  projectIds: string[];
  consentAt: Date;
  tutorialAt: string;
}

export interface IUser extends IBaseModel {
  firstName?: string;
  lastName?: string;
  name?: string;
  email?: string;
  role?: UserRoleEnum;
  permissions?: IAccountProject[];
  userRoles?: IUserRole[];
  status?: UserStatusEnum;
  tutorialAt?: string;

  _user?: Partial<TranslationUser>;
}

export interface IUserAccount extends IUser {
  projects: IAccountProject[];
  projectIds?: string[]
}

export interface IAccountProject extends IProject {
  projectId: string;
  role: UserRoleEnum;
}

export interface IUserRole {
  role?: UserRoleEnum;
}

export interface IUserPayload extends IBaseModel {
  pageSize: number;
  pageIndex: number;
  keyword?: string;
  status?: UserStatusEnum;
}

export interface IUserActionType extends IBaseModel {
  title?: string;
  text?: string;
  actionType?: AccountActionType;
  isShowActionBtn: boolean;
}
