import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IVariableSyntaxTypeInterface } from 'src/shared/interfaces/variable-syntax-type.interface';
import { ITranslationSchema } from './text-editor-slate/text-editor.interface';

@Component({
  selector: 'app-translation-row',
  templateUrl: './translation-row.component.html',
  styleUrls: ['./translation-row.component.scss'],
})
export class TranslationRowComponent {
  @Input() paramSyntax: IVariableSyntaxTypeInterface;
  @Input() headerClass: string;
  @Input() targetLanguage: string;
  @Input() isRowEditable = true;
  @Input() isSourceLanguage = false;
  @Input() mainLanguageTranslationSchema: ITranslationSchema;
  @Input() dataSource: ITranslationSchema;
  @Input() staticDatasource: ITranslationSchema
  @Input() projectId: string;
  @Input() isRemoveSectionDomain = false;
  @Input() hasKeyNotes: boolean;
  @Input() isHasHistory: boolean;
  @Input() hasAudio = false

  @Output() copyTextClicked = new EventEmitter();
  @Output() contentChanged = new EventEmitter();
  @Output() blurEditor = new EventEmitter();
  @Output() historyLog = new EventEmitter<void>();

  onContentChanged(changedContent: { [key: string]: string }) {
    this.contentChanged.emit(changedContent);
  }

  onRawEditorContentChanged(changedContent: { [key: string]: string }) {
    this.contentChanged.emit(changedContent)
  }
}
