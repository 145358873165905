import { TranslationErrorEnum } from '../enums/language-status.enum';

export const LANGUAGE_ASSETS = {
  ar: {
    flag: '/assets/icons/flag-ar.svg',
    name: 'Arabic',
  },
  'ar-sa': {
    flag: '/assets/icons/flag-ar.svg',
    name: 'Arabic',
  },
  bg: {
    flag: '/assets/icons/flag-bg.svg',
    name: 'Bulgarian',
  },
  cs: {
    flag: '/assets/icons/flag-cs.svg',
    name: 'Czech',
  },
  da: {
    flag: '/assets/icons/flag-da.svg',
    name: 'Danish',
  },
  'da-dk': {
    flag: '/assets/icons/flag-da.svg',
    name: 'Danish',
  },
  de: {
    flag: '/assets/icons/flag-de_at.svg',
    name: 'German - DE_AT',
  },
  'de-de': {
    flag: '/assets/icons/flag-de_at.svg',
    name: 'German - DE_AT',
  },
  'de-de_at': {
    flag: '/assets/icons/flag-de_at.svg',
    name: 'German - DE_AT',
  },
  el: {
    flag: '/assets/icons/flag-el.svg',
    name: 'Greek',
  },
  en: {
    flag: '/assets/icons/flag-en-uk.svg',
    name: 'English - UK',
  },
  'en-en': {
    flag: '/assets/icons/flag-en-uk.svg',
    name: 'English - UK',
  },
  'en-india': {
    flag: '/assets/icons/flag-in.svg',
    name: 'English - INDIA',
  },
  'en-uk': {
    flag: '/assets/icons/flag-en-uk.svg',
    name: 'English - UK',
  },
  'en-gb': {
    flag: '/assets/icons/flag-en-uk.svg',
    name: 'English - UK',
  },
  'en-us': {
    flag: '/assets/icons/flag-en-us.svg',
    name: 'English - US',
  },
  'en-ca': {
    flag: '/assets/icons/flag-en-ca.svg',
    name: 'English - CA',
  },
  'en-aus_nz': {
    flag: '/assets/icons/flag-en-aus_nz.svg',
    name: 'English - AUS_NZ',
  },
  'en-aus': {
    flag: '/assets/icons/flag-en-aus.svg',
    name: 'English - AUS',
  },
  'en-sg': {
    flag: '/assets/icons/flag-en-sg.svg',
    name: 'English - SG',
  },
  'es-es': {
    flag: '/assets/icons/flag-es-es.svg',
    name: 'Spanish - ES',
  },
  'es-ar': {
    flag: '/assets/icons/flag-es-ar.svg',
    name: 'Spanish - AR',
  },
  'es-mx': {
    flag: '/assets/icons/flag-es-mx.svg',
    name: 'Spanish - MX',
  },
  fi: {
    flag: '/assets/icons/flag-fi.svg',
    name: 'Finnish',
  },
  fr: {
    flag: '/assets/icons/flag-fr-fr_be.svg',
    name: 'French - FR_BE',
  },
  'fr-ca': {
    flag: '/assets/icons/flag-fr-fr_be.svg',
    name: 'French - CA',
  },
  'fr-fr': {
    flag: '/assets/icons/flag-fr-fr_be.svg',
    name: 'French - FR_BE',
  },
  'fr-fr_be': {
    flag: '/assets/icons/flag-fr-fr_be.svg',
    name: 'French - FR_BE',
  },
  he: {
    flag: '/assets/icons/flag-he.svg',
    name: 'Hebrew',
  },
  hr: {
    flag: '/assets/icons/flag-hr.svg',
    name: 'Croatian',
  },
  'hr-hr': {
    flag: '/assets/icons/flag-hr.svg',
    name: 'Croatian',
  },
  hu: {
    flag: '/assets/icons/flag-hu.svg',
    name: 'Hungarian',
  },
  id: {
    flag: '/assets/icons/flag-id.svg',
    name: 'Indonesian',
  },
  it: {
    flag: '/assets/icons/flag-it.svg',
    name: 'Italian',
  },
  'it-it': {
    flag: '/assets/icons/flag-it.svg',
    name: 'Italian',
  },
  ja: {
    flag: '/assets/icons/flag-ja.svg',
    name: 'Japanese',
  },
  'ja-jp': {
    flag: '/assets/icons/flag-ja.svg',
    name: 'Japanese',
  },
  ko: {
    flag: '/assets/icons/flag-ko.svg',
    name: 'Korean',
  },
  'ko-kr': {
    flag: '/assets/icons/flag-ko.svg',
    name: 'Korean',
  },
  ms: {
    flag: '/assets/icons/flag-ms.svg',
    name: 'Malay',
  },
  nb: {
    flag: '/assets/icons/flag-nb.svg',
    name: 'Norwegian',
  },
  nl: {
    flag: '/assets/icons/flag-nl.svg',
    name: 'Dutch',
  },
  'nl-nl': {
    flag: '/assets/icons/flag-nl.svg',
    name: 'Dutch',
  },
  pl: {
    flag: '/assets/icons/flag-pl.svg',
    name: 'Polish',
  },
  'pl-pl': {
    flag: '/assets/icons/flag-pl.svg',
    name: 'Polish',
  },
  'pt-pt': {
    flag: '/assets/icons/flag-pt-pt.svg',
    name: 'Portuguese - PT',
  },
  'pt-br': {
    flag: '/assets/icons/flag-pt-br.svg',
    name: 'Portuguese - BR',
  },
  ro: {
    flag: '/assets/icons/flag-ro.svg',
    name: 'Romanian',
  },
  ru: {
    flag: '/assets/icons/flag-ru.svg',
    name: 'Russian',
  },
  'ru-ru': {
    flag: '/assets/icons/flag-ru.svg',
    name: 'Russian',
  },
  sk: {
    flag: '/assets/icons/flag-sk.svg',
    name: 'Slovak',
  },
  sl: {
    flag: '/assets/icons/flag-sl.svg',
    name: 'Slovenian',
  },
  sr: {
    flag: '/assets/icons/flag-sr.svg',
    name: 'Serbian',
  },
  sv: {
    flag: '/assets/icons/flag-sv.svg',
    name: 'Swedish',
  },
  th: {
    flag: '/assets/icons/flag-th.svg',
    name: 'Thai',
  },
  tk: {
    flag: '/assets/icons/flag-tk.svg',
    name: 'Turkish',
  },
  uk: {
    flag: '/assets/icons/flag-uk.svg',
    name: 'Ukrainian',
  },
  vi: {
    flag: '/assets/icons/flag-vi.svg',
    name: 'Vietnamese',
  },
  'zh-cn': {
    flag: '/assets/icons/flag-zh-cn.svg',
    name: 'Simplified Chinese - CN',
  },
  'zh-hk': {
    flag: '/assets/icons/flag-zh-hk.svg',
    name: 'Traditional Chinese - HK',
  },
  'zh-tw': {
    flag: '/assets/icons/flag-zh-tw.svg',
    name: 'Traditional Chinese -TW',
  },
  'zh-sg': {
    flag: '/assets/icons/flag-zh-sg.svg',
    name: 'Simplified Chinese - SG',
  },
  default: {
    flag: '/assets/icons/flag-default.svg',
  },
};

export const CONFLICT_CODE_ASSETS = {
  [TranslationErrorEnum.KEY_DELETED]: {
    class: 'color--red-100 p--small',
    label: 'Draft.Conflict.Deleted',
  },
  [TranslationErrorEnum.MISSING_KEY]: {
    class: 'color--red-100 p--small font-weight--500',
    label: 'Draft.Conflict.MissingKey',
  },
  [TranslationErrorEnum.VALUE_EMPTY]: {
    class: 'color--gold-100 p--small font-weight--500',
    label: 'Draft.Conflict.ValueEmpty',
  },
  [TranslationErrorEnum.VALUE_CONFLICT]: {
    class: 'color--gold-100 p--small font-weight--500',
    label: 'Draft.Conflict.ValueConflict',
  },
  [TranslationErrorEnum.HEADER_MAIN_MISSING]: {
    class: 'color--red-100 p--small font-weight--500',
    label: 'Draft.Conflict.HeaderMainMissing',
  },
  [TranslationErrorEnum.HEADER_LANGUAGE_MISSING]: {
    class: 'color--red-100 p--small font-weight--500',
    label: 'Draft.Conflict.HeaderLanguageMissing',
  },
  [TranslationErrorEnum.FILE_EMPTY_CONTENT]: {
    class: 'color--red-100 p--small font-weight--500',
    label: 'Draft.Conflict.FileEmptyContent',
  },
  [TranslationErrorEnum.HAVE_ACTIVATE_DRAFT]: {
    class: 'color--red-100 p--small font-weight--500',
    label: 'Draft.Conflict.HaveActivateDraft.Label',
  },
  [TranslationErrorEnum.VARIABLE_UNKNOWN]: {
    class: 'color--red-100 p--small font-weight--500',
    label: 'Draft.Conflict.VariableUnknown',
  },
  [TranslationErrorEnum.VARIABLE_MISSING]: {
    class: 'color--gold-100 p--small font-weight--500',
    label: 'Draft.Conflict.VariableMissing',
  },
  [TranslationErrorEnum.VARIABLE_NOT_USED]: {
    class: 'color--gold-100 p--small font-weight--500',
    label: 'Draft.Conflict.VariableNotUsed',
  },
  [TranslationErrorEnum.KEY_NOT_FOUND]: {
    class: 'color--gold-100 p--small font-weight--500',
    label: 'Draft.Conflict.KeyNotFound',
  },
  [TranslationErrorEnum.COMMON_ERROR]: {
    class: 'color--red-100 p--small font-weight--500',
    label: 'Draft.Conflict.CommonError',
  },
  [TranslationErrorEnum.FILE_FORMAT]: {
    class: 'color--red-100 p--small font-weight--500',
    label: 'Draft.Conflict.FileFormat',
  },
  [TranslationErrorEnum.FILE_HEADER_KEYS]: {
    class: 'color--red-100 p--small font-weight--500',
    label: 'Draft.Conflict.FileHeaderKeys',
  },
  [TranslationErrorEnum.FILE_VALUE_LANGUAGE_CODE]: {
    class: 'color--red-100 p--small font-weight--500',
    label: 'Draft.Conflict.FileValueLanguageCode',
  },
  [TranslationErrorEnum.NOT_READY_TO_PUBLISH]: {
    class: 'color--red-100 p--small font-weight--500',
    label: 'Draft.Conflict.NotReadyToPublic',
  },
  [TranslationErrorEnum.UNKNOWN]: {
    class: 'color--red-100 p--small font-weight--500',
    label: 'Draft.Conflict.Unknown',
  },
  [TranslationErrorEnum.REJECTED]: {
    class: 'text-bubble__title__text--rejected p--small font-weight--500',
    label: 'Draft.Conflict.KeysNotReady.Rejected',
  },
  [TranslationErrorEnum.WIP]: {
    class: 'text-bubble__title__text--modified p--small font-weight--500',
    label: 'Draft.Conflict.KeysNotReady.WIP',
  },
};
