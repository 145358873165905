import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {
  PodcastVoiceComponent
} from '../../../../../../../podcast-group/podcast/podcast-creation-dialog/podcast-voice/podcast-voice.component';
import { NgIf } from '@angular/common';
import {
  ToggleVoiceSettingsComponent
} from '../../../../../../../podcast-group/podcast/podcast-language-details/podcast-language-keys/podcast-key/toggle-voice-settings/toggle-voice-settings.component';

@Component({
  selector: 'app-editor-voice',
  standalone: true,
  imports: [
    PodcastVoiceComponent,
    NgIf,
    ToggleVoiceSettingsComponent,
  ],
  templateUrl: './editor-voice.component.html',
  styleUrl: './editor-voice.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditorVoiceComponent {
  @Input() isEditable: boolean;
  @Input() voiceId: string;
  @Input() isVoiceSettingsOpened: boolean;

  @Output() toggleVoiceSettings = new EventEmitter<void>();
  @Output() selectVoice = new EventEmitter<string>();
}
