import {
  Component,
  ChangeDetectionStrategy,
  Input,
  TemplateRef,
  ViewContainerRef,
  NgZone,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  Renderer2,
  ChangeDetectorRef,
  ViewEncapsulation,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { createPopper } from '@popperjs/core';
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { IconName, OptionDisplayPipe, PipesModule } from '@app/shared';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [ CommonModule, ScrollingModule, PipesModule, MatMenuModule, MatIconModule, TranslateModule, OptionDisplayPipe, SvgIconComponent ],
})
export class DropdownComponent<T> implements OnDestroy {
  public readonly ICON_NAMES = IconName;
  public popperRef: any;
  public view: any;
  public originalDataSource: T[];
  public originalSelectedOption: T;

  public isDropdownOpened: boolean;

  @Input() prefixIcon: string;
  @Input() placeholder: string;
  @Input() isDisabled: boolean;
  @Input() translationObject: any;
  @Input() set maxSize(value: number) {
    if (!value) {
      return;
    }

    this._maxSize = value;

    if (value > 5) {
      this._maxSize = 5;
    }
  }
  get maxSize(): number { return this._maxSize }

  @Input() set dataSource(value: any[]) {
    if (!value) {
      return;
    }
    this.originalDataSource = [...value];
  }
  @Input() set selectedOption(value: any) {
    if (!value) {
      return;
    }
    this.originalSelectedOption = value;
  }
  @ViewChild('originSelect') originSelect: ElementRef;
  @Output() optionSelected = new EventEmitter<T>();
  @Output() dropdownClosed = new EventEmitter();
  @Output() dropdownOpened = new EventEmitter();

  private _maxSize: number;


  @HostListener('document:click', ['$event'])
  public onClickOutSide(event) {
    if (!this.originSelect.nativeElement.contains(event.target)) {
      this.close();
    }
  }
  constructor(
    private vcr: ViewContainerRef,
    private zone: NgZone,
    private _renderer: Renderer2,
    private _changeDetector: ChangeDetectorRef,
  ) {}

  open(dropdownTpl: TemplateRef<any>, origin: HTMLElement) {
    if (this.popperRef) {
      this.close();
      return;
    }
    this._renderer.addClass(this.originSelect.nativeElement, 'select__opened');
    this.view = this.vcr.createEmbeddedView(dropdownTpl);
    const dropdown = this.view.rootNodes[0];

    this.originSelect.nativeElement.appendChild(dropdown);
    dropdown.style.width = `${origin.offsetWidth}px`;

    this.zone.runOutsideAngular(() => {
      this.popperRef = createPopper(origin, dropdown);
    });
    this.dropdownOpened.emit();
    this.isDropdownOpened = true;
  }

  close() {
    this.popperRef?.destroy();
    this._renderer.removeClass(this.originSelect.nativeElement, 'select__opened');
    this.view?.destroy();
    this.view = null;
    this.popperRef = null;
    this._changeDetector.detectChanges();
    this.dropdownClosed.emit();
    this.isDropdownOpened = false;
  }

  select(item: any) {
    if (!this.popperRef || this.originalSelectedOption === item) {
      return;
    }

    this.originalSelectedOption = item;

    if (!this.placeholder) {
      this.optionSelected.emit(item);
      this.close();
      return;
    }

    if (typeof item === 'string' && item === this.placeholder || typeof item === 'object' && item.title === this.placeholder) {
      this.originalSelectedOption = null;
      this.optionSelected.emit(null);
      this.close();
      return;
    }

    this.optionSelected.emit(item);
    this.close();
  }

  ngOnDestroy(): void {
    this.close();
  }
}
