<button
  class="btn__outline--leading flex items-center gap-2 px-6 py-[9px] clickable"
  [class.active]="isVoiceSettingsOpened"
  (click)="toggleVoiceSettings.emit()"
>
  <span>{{'Menu.Item.Settings.Label' | translate}}</span>

  <svg-icon
    [src]="'/assets/icons/podcast/' + (isVoiceSettingsOpened ? 'cross' : 'settings') + '.svg'"
    [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
  ></svg-icon>
</button>

