import { BaseApiService } from '../../../core/services/base-api.service';
import { IProjectKey } from './project-key.interface';
import { tap } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class ProjectKeyService extends BaseApiService<IProjectKey>{
  protected apiUrl = 'project-keys';

  public projectKeyMap = new Map<string, IProjectKey>();

  public findConfig(key: string, language: string, projectId: string): IProjectKey {
    return this.projectKeyMap.get(key) || { key, language, projectId } as IProjectKey;
  }

  public updateKeyConfig(payload: Partial<IProjectKey>) {
    return this.create(payload).pipe(tap((response) => {
      this.projectKeyMap.set(response.key, response);
    }))
  }

  public getProjectKeyConfig(projectId: string, language: string) {
    const httpParams = new HttpParams().appendAll({ language, projectId });
    return this.getList(httpParams).pipe(tap((response) => {
      if (response?.items) {
        response.items.forEach(item => {
          if (item.language === language) {
            this.projectKeyMap.set(item.key, item);
          }
        });
      }
    }))
  }
}
