import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { IconName } from '@app/shared';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { INote } from '../text-editor-slate/text-editor.interface';

@Component({
  selector: 'app-key-notes',
  templateUrl: './key-notes.component.html',
  styleUrls: ['./key-notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyNotesComponent implements OnChanges {
  public readonly IconName = IconName;

  public isAddNewKeyNote = false;
  public cachedKeyNoteValue: string;
  public isFocusing: boolean;
  @Input() public isAdmin: boolean;
  @Input() public note: INote;
  @Output() public keyNoteChanged = new EventEmitter<INote>();
  @ViewChild('inputKeyNote') element: ElementRef;
  @ViewChild('autoSize') autoSize: CdkTextareaAutosize;

  ngOnChanges() {
    this.cachedKeyNoteValue = this.note?.value;
  }

  public onKeyNoteChange(value: string): void {
    this.cachedKeyNoteValue = value;
  }

  public onAddKeyNote(): void {
    this.isAddNewKeyNote = true;
  }

  public onBlur() {
    if (!!this.cachedKeyNoteValue && this.cachedKeyNoteValue === this.note?.value) {
      return;
    }

    this.note = {
      ...this.note,
      value: this.cachedKeyNoteValue,
    };

    if (!!this.note.value || !!this.cachedKeyNoteValue || !!this.note.id) {
      this.keyNoteChanged.emit(this.note);
    }

    this.isAddNewKeyNote = false;
  }

  public onFocus(isFocus: boolean) {
    this.isFocusing = isFocus;
  }
}
