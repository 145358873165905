import { DialogConfig } from '@angular/cdk/dialog';
import { CommonConstant, DialogConfigByStatus, DialogConfigConst, DraftDialogErrorsBlockingFlow } from '@app/shared';
import { TranslateService } from '@ngx-translate/core';
import { finalize, of, switchMap } from 'rxjs';
import { IProjectDetailLanguage } from 'src/shared/components/project-detail-language/project-detail-language.interface';
import { DRAFT_STATUS_NAME } from 'src/shared/constants/draft-status.const';
import { CONFLICT_CODE_ASSETS, LANGUAGE_ASSETS } from 'src/shared/constants/language-assets.constant';
import { DialogModeEnum } from 'src/shared/enums/dialog.enum';
import { DraftKeyStatusEnum, LanguageStatusEnum, TranslationErrorEnum } from 'src/shared/enums/language-status.enum';
import { ErrorDialogItem, IDraftError, ITranslationMessageDialog } from 'src/shared/interfaces/dialog.interface';
import { DraftKeyStatus, DraftKeyStatusIcons, PageMode, translationErrorCodeOrder } from './language-detail.const';
import { LanguageFixTranslationActionEnum, TranslationErrorSeverityEnum } from './language-detail.enum';
import { ICurrentState, IPageMode } from './language-detail.interface';
import { ITranslationSchema } from './translation-tool/translate-to/translation-row/text-editor-slate/text-editor.interface';

export function getTranslationSchema(schemaSource: ITranslationSchema, index: number, currentState: ICurrentState): ITranslationSchema {
  let draftKeyStatus = DraftKeyStatus[schemaSource?.status] || DraftKeyStatus[DraftKeyStatusEnum.DEFAULT];
  if (schemaSource?.isEdited && (!schemaSource?.status || schemaSource?.status == null)) {
    draftKeyStatus = DraftKeyStatus[DraftKeyStatusEnum.EDITED];
  }
  return {
    ...schemaSource,
    isAdmin: currentState?.isAdmin,
    index: index,
    value: schemaSource?.value,
    displayKey: !!CommonConstant.INTEGRATION_MODES[currentState?.projectState?.integrationMode]
      ? removeSectionName(schemaSource?.name, schemaSource?.section)
      : schemaSource?.name,
    params: schemaSource?.variables,
    draftKeyStatus: {
      ...draftKeyStatus,
      draftStatusIcons: DraftKeyStatusIcons.filter((item) => !item?.isHide(currentState)),
    },
  };
}

export function getPageMode(languageStatus: LanguageStatusEnum) {
  return { ...PageMode[languageStatus] };
}

export function getLanguageByCode(code: string, source: IProjectDetailLanguage[]) {
  return source.find((item) => item.code === code);
}

export function filterDataByCondition(source: any, filteredFnc: Function) {
  return source.filter(filteredFnc);
}

export function updateTranslationSchemaByContentObject(
  translationSchemas: ITranslationSchema[],
  updatedContent: object,
  pageMode?: IPageMode,
) {
  Object.keys(updatedContent).forEach((key: string) => {
    const updatedSchema = translationSchemas.find((translation) => translation.name === key);
    updatedSchema['value'] = updatedContent[key] || '';
    if (updatedSchema?.status === DraftKeyStatusEnum.EDITED) {
      updatedSchema['status'] = updatedSchema?.draftKeyStatus?.status;
      updatedSchema['draftKeyStatus'] = {
        ...DraftKeyStatus[updatedSchema?.draftKeyStatus?.status],
        draftStatusIcons: updatedSchema?.draftKeyStatus?.draftStatusIcons,
      };
    }
  });
  return translationSchemas;
}

export function groupKeyByErrorCode(dataSource: IDraftError[]) {
  return dataSource.reduce((acc, item) => {
    const errorCode = !!item?.data?.editedItemStatus ? item?.data?.editedItemStatus : item?.errorCode;
    const errorObject = { ...CONFLICT_CODE_ASSETS[errorCode], type: item?.data?.editedItemStatus ?? item?.type };
    if (!acc[errorObject?.label]) {
      acc[errorObject?.label] = getNewErrorCodeItem(errorObject);
    }
    const displayKey = item?.displayKey || item?.key;
    if (!!displayKey) {
      acc[errorObject?.label].contents = [
        ...acc[errorObject?.label].contents,
        {
          contentClass: 'p--small font-weight--400',
          content: displayKey,
        },
      ];
    }
    acc[errorObject?.label].contents = Object.values(
      acc[errorObject?.label].contents.reduce((sum, item, idx) => {
        if (!!sum[item?.content]) {
          return sum;
        }
        return { ...sum, ...{ [item?.content]: item } };
      }, {}),
    );
    acc[errorObject?.label].message = item.message;
    return acc;
  }, {});
}

export function sortErrorBySeverity(dataSource: IDraftError[]) {
  return dataSource?.sort((a: IDraftError, b: IDraftError) => {
    return translationErrorCodeOrder[a?.type] - translationErrorCodeOrder[b?.type];
  });
}

export function getImportSourceDataDialog(dataSource: IDraftError[], translateService: TranslateService) {
  const output = {};
  dataSource?.forEach((data: IDraftError) => {
    const errorObject = { ...CONFLICT_CODE_ASSETS[data?.errorCode], type: data?.type };
    if (!output[errorObject?.label]) {
      output[errorObject?.label] = getNewErrorCodeItem(errorObject);
    }
    data?.data?.drafts?.forEach((draft) => {
      output[errorObject?.label].contents = [
        ...output[errorObject?.label].contents,
        {
          contentClass: 'p--small font-weight--400',
          content: `${LANGUAGE_ASSETS[draft?.language?.toLowerCase()]?.name || draft?.language} (Status: ${translateService.instant(
            DRAFT_STATUS_NAME[draft?.status],
          )})`,
        },
      ];
    });
  });
  return Object.values(output);
}

export function getNewErrorCodeItem(initialValue: ErrorDialogItem) {
  return {
    title: initialValue?.label,
    titleClass: initialValue?.class,
    type: initialValue.type,
    contents: [],
  };
}

export function handleNotReadyPublishConflictedTranslation(errors: any[], targetStatus: LanguageStatusEnum) {
  const notReadyToPublishErrors = errors?.filter((error) => error?.errorCode === TranslationErrorEnum.NOT_READY_TO_PUBLISH);
  const errorsExcludingNotReadyToPublishErrors = errors?.filter(
    (error) => error?.errorCode !== TranslationErrorEnum.NOT_READY_TO_PUBLISH,
  );
  const dialogConfigs = getUpdateDraftDialogConfigByErrorCode(
    errorsExcludingNotReadyToPublishErrors,
    this.dialogConfigConst[DialogModeEnum.PUBLISH_TRANSLATION],
  );
  const notReadyToPublishErrorDialog = this.dialogConfigConst[DialogModeEnum.UPDATE_DRAFT_KEY_STATUS];
  // one more step to validate when having not ready to publish error, the draft status only updated at the final step.
  if (errorsExcludingNotReadyToPublishErrors?.length == 0 && notReadyToPublishErrors?.length > 0) {
    return handleConflictedTranslation.apply(this, [
      notReadyToPublishErrorDialog,
      notReadyToPublishErrors,
      this.originalDataSource?.status,
      targetStatus,
      true,
    ]);
  }

  return handleConflictedTranslation
    .apply(this, [
      dialogConfigs,
      errorsExcludingNotReadyToPublishErrors,
      this.originalDataSource?.status,
      notReadyToPublishErrors?.length > 0 ? this.originalDataSource?.status : targetStatus,
      notReadyToPublishErrors?.length == 0,
    ])
    .pipe(
      switchMap((res: any) => {
        if ([DialogModeEnum.CANCEL, DialogModeEnum.CANCEL_LITE_FIX].includes(res)) {
          return of(res);
        } else if (notReadyToPublishErrors?.length === 0) {
          return of(DialogModeEnum.UPDATE_DRAFT_INFO);
        }
        return handleConflictedTranslation.apply(this, [
          notReadyToPublishErrorDialog,
          notReadyToPublishErrors,
          this.originalDataSource?.status,
          targetStatus,
          true,
        ]);
      }),
    );
}

export function handleConflictedTranslation(
  dialogConfig: DialogConfig,
  errorCodes: IDraftError[],
  currentPageStatus: LanguageStatusEnum,
  redirectPageStatus: LanguageStatusEnum,
  isFixDraftKeyStatus?: boolean,
) {
  return this._getTranslationErrorDialog({
    ...dialogConfig,
    data: sortErrorBySeverity(Object.values(groupKeyByErrorCode(errorCodes))),
  }).pipe(
    switchMap((response) => {
      if (response === DialogModeEnum.CANCEL) {
        return of(response)
      }

      this._localSpinnerService.startLocalSpinner(this.LOCAL_SPINNER_ID);
      if (response === DialogModeEnum.UPDATE_DRAFT_INFO || !response) {
        this.pageMode = getPageMode(LanguageStatusEnum.TO_REVIEW);
        return of(DialogModeEnum.UPDATE_DRAFT_INFO);
      }

      if (response === DialogModeEnum.UPDATE_DRAFT_KEY_STATUS_DO_NOTHING) {
        return of(DialogModeEnum.UPDATE_DRAFT_KEY_STATUS_DO_NOTHING);
      }

      if (response === DialogModeEnum.CANCEL_LITE_FIX) {
        this.searchInput.searchControl.setValue('');
        return this._projectsService.liteFixTheTranslation(this.draftId, LanguageFixTranslationActionEnum.LITE_FIX).pipe(
          switchMap(() => {
            return this._initialActions();
          }),
          switchMap(() => {
            return of(response);
          }),
        );
      }

      return this._projectsService.fullFixTheTranslation(
        this.draftId,
        LanguageFixTranslationActionEnum.FULL_FIX,
        redirectPageStatus,
        isFixDraftKeyStatus,
      );
    }),
    finalize(() => {
      this._localSpinnerService.stopLocalSpinner(this.LOCAL_SPINNER_ID);
    }),
  );
}

export function handleEditedDraftItems(editedItems: ITranslationSchema[], translationSchemaSource: ITranslationSchema[]) {
  const editedItemsMap = editedItems?.reduce((acc, item, idx) => {
    if (!item?.status || item?.status === DraftKeyStatusEnum.EDITED) {
      return acc.set(item?.name, item);
    }
    return acc;
  }, new Map());
  translationSchemaSource?.forEach((translationSchema) => {
    if (editedItemsMap?.has(translationSchema?.name)) {
      translationSchema['isEdited'] = true;
      translationSchema['draftKeyStatus']['status'] = DraftKeyStatusEnum.EDITED;
      translationSchema['status'] = translationSchema?.draftKeyStatus?.status;
      translationSchema['value'] = editedItemsMap.get(translationSchema.name).value;
      translationSchema['isEditAfterDraftRejected'] = editedItemsMap?.get(translationSchema?.name)?.isEditAfterDraftRejected;
      translationSchema['editedItemId'] =
        editedItemsMap?.get(translationSchema?.name)?.id || editedItemsMap?.get(translationSchema?.name)?.editedItemId;
    }
  });
}

export function getUpdateDraftDialogConfigByErrorCode(errors: IDraftError[], defaultDialogConfig: ITranslationMessageDialog) {
  const dialogConfigs = errors?.every(
    (error) =>
      error?.type === TranslationErrorSeverityEnum.INFO ||
      DialogConfigByStatus[error?.errorCode] === DialogModeEnum.IMPORT_DRAFT_INFO,
  )
    ? DialogConfigConst[DialogModeEnum.UPDATE_DRAFT_INFO]
    : defaultDialogConfig;
  return dialogConfigs;
}
export function getImportDraftDialogConfigByErrorCode(errors: IDraftError[], defaultDialogConfig: ITranslationMessageDialog) {
  let dialogConfigs = defaultDialogConfig;
  if (errors?.every((err) => err?.type === TranslationErrorSeverityEnum.INFO)) {
    dialogConfigs = DialogConfigConst[DialogModeEnum.IMPORT_DRAFT_INFO];
  } else if (errors?.some((err) => !!DraftDialogErrorsBlockingFlow[err?.errorCode])) {
    dialogConfigs = DialogConfigConst[DialogModeEnum.IMPORT_DRAFT_BLOCK_FLOW_DO_NOTHING];
  }
  return dialogConfigs;
}
export function getImportSourceDialogConfigByErrorCode(errors: IDraftError[], defaultDialogConfig: ITranslationMessageDialog) {
  const dialogConfigs = errors?.some((error) => error?.errorCode !== TranslationErrorEnum.HAVE_ACTIVATE_DRAFT)
    ? defaultDialogConfig
    : DialogConfigConst[DialogModeEnum.IMPORT_SOURCE_HAVE_ACTIVATED_DRAFT];
  return dialogConfigs;
}

export function removeSectionName(value: string, sectionName: string): string {
  const splittedName = value?.split('.');
  let output = value;
  if (!!sectionName && splittedName?.length > 1) {
    if (splittedName[0] === sectionName) {
      output = splittedName.slice(1, splittedName?.length).join('.');
    }
  }
  return output;
}
