import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-eleven-labs-setting',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
  ],
  templateUrl: './eleven-labs-setting.component.html',
  styleUrl: './eleven-labs-setting.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElevenLabsSettingComponent implements AfterViewInit {
  @Input() settingName: string;
  @Input() labelMin: string;
  @Input() labelMax: string;
  @Input() settingValue: number;

  @Output() settingValueChanged = new EventEmitter<number>();

  @ViewChild('seekbar') seekbar: ElementRef<HTMLInputElement>;

  get settingSeekbar() {
    return this.seekbar.nativeElement;
  }

  ngAfterViewInit() {
    this._updateSeekbarStyle();

    this.settingSeekbar.oninput = () => {
      this._updateSeekbarStyle();
      this.settingValueChanged.emit(this.settingValue);
    }
  }

  private _updateSeekbarStyle() {
    this.settingSeekbar.style.setProperty('--seek-progress', `${this.settingValue}%`);
  }
}
