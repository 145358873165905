
<ng-content></ng-content>

<app-dropdown
  [maxSize]="4"
  prefixIcon="assets/icons/podcast/voices.svg"
  [placeholder]="'Podcast.Creation.Voice.Placeholder' | translate"
  [selectedOption]="defaultOption"
  [dataSource]="voices"
  [isDisabled]="isDisabled"
  (optionSelected)="onOptionSelected($event)"
></app-dropdown>
