import { CommonModule, NgIf, UpperCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  signal
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { BaseComponent, IconName, SvgIconComponent } from '@app/shared';
import { TranslateModule } from '@ngx-translate/core';
import { catchError, combineLatest, finalize, Subject, tap } from 'rxjs';
import { AudioRecorderComponent } from '../audio-recorder/audio-recorder.component';
import { TranslationStatusEnum } from '../text-editor.enum';
import { MatTooltip } from '@angular/material/tooltip';
import { DraftAssetService } from '../../../../../../../core/services/draft-asset.service';
import { LANGUAGE_CONFIG_MAP } from '../../../../../../../../shared/constants/language-config-map.const';
import { EditorVoiceComponent } from './editor-voice/editor-voice.component';
import {
  PodcastVoiceSettingsComponent
} from '../../../../../../podcast-group/podcast/podcast-language-details/podcast-language-keys/podcast-key/podcast-voice-settings/podcast-voice-settings.component';
import { ProjectKeyService } from '../../../../../project-key/project-key.service';
import { IProjectKey } from '../../../../../project-key/project-key.interface';
import { IPodcastVoiceConfig, IPodcastVoiceSettings } from '../../../../../../podcast-group/podcast/podcast.interface';
import { debounceTime } from 'rxjs/operators';
import { ProjectsService } from '../../../../../../../core/services/projects.service';
import { PodcastService } from '../../../../../../podcast-group/podcast/podcast.service';

@Component({
  selector: 'app-editor-footer',
  standalone: true,
  imports: [
    CommonModule,
    MatIcon,
    NgIf,
    TranslateModule,
    UpperCasePipe,
    SvgIconComponent,
    AudioRecorderComponent,
    MatTooltip,
    EditorVoiceComponent,
    PodcastVoiceSettingsComponent,
  ],
  templateUrl: './editor-footer.component.html',
  styleUrl: './editor-footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditorFooterComponent extends BaseComponent implements OnChanges {
  public readonly TranslationStatusEnum = TranslationStatusEnum;
  public readonly LANGUAGE_CONFIG_MAP = LANGUAGE_CONFIG_MAP;

  protected readonly ICON_NAMES = IconName;

  public translationStatus: TranslationStatusEnum;
  public isVoiceSettingsOpened: boolean;
  public projectKeyConfig: IProjectKey;
  public isVoiceSettingChanged: boolean;

  public audioUrl = signal<string | null>(null);
  public isAudioRecorderEnabled = signal<boolean>(false);

  public isLoading = signal(false)

  public showError = false;

  @Input() translationKey: string;
  @Input() language: string;
  @Input() isTextareaEnabled: boolean;
  @Input() set isEditable(value: boolean) {
    this._isEditable = value;
    if (value) {
      this._initVoiceConfig();
    }
  }
  get isEditable(): boolean {
    return this._isEditable;
  }
  @Input() allowAudio = false;
  @Input() disableAudio = false;
  @Input() hasAudio = false;
  @Input() isSourceLanguage: boolean;

  @Output() translateText = new EventEmitter<void>()

  @Output() addItem = new EventEmitter<File>();
  @Output() removeItem = new EventEmitter<void>();
  @Output() generateAudio = new EventEmitter<void>();

  private _isEditable = false;
  private _configUpdateEvents = new Subject<Partial<IPodcastVoiceConfig>>();

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _draftAssetService: DraftAssetService,
    private _projectKeyService: ProjectKeyService,
    private _projectsService: ProjectsService,
    private _podcastService: PodcastService,
  ) {
    super()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.isSourceLanguage?.firstChange) {
      this.updateAudioUrl()
    }
  }

  protected onInit() {
    this.updateAudioUrl()

    this.subscribe(combineLatest([ this._draftAssetService.getItemObs(), this._draftAssetService.getSourceItemObs(), ])
      .pipe(tap(() => this.updateAudioUrl())));

    this.subscribe(this._configUpdateEvents.pipe(debounceTime(500)), (config) => this._updateVoiceSettings(config));
  }

  public onTranslateText() {
    this.translateText.next()
  }

  private updateAudioUrl() {
    if (!this.translationKey) { return }

    const url = this.isSourceLanguage
      ? this._draftAssetService.sourceAudio?.newValue?.[this.translationKey]
      : this._draftAssetService.audio?.newValue?.[this.translationKey]

    this.audioUrl.set(url);
  }

  public onRecordAudioClicked() {
    if (this.isLoading()) { return }

    this.isAudioRecorderEnabled.set(true)
  }
  public onAddAudio(file: File) {
    this.isLoading.set(true)

    this.subscribe(this._draftAssetService.addItem(this.translationKey, file).pipe(
      tap(() => this.isAudioRecorderEnabled.set(false)),
      catchError((error) => {
        this.showError = true;
        throw error;
      }),
      finalize(() => {
        this.isLoading.set(false);
        this._changeDetectorRef.detectChanges();
      }),
    ))
  }

  public onRemoveAudio() {
    this.isLoading.set(true)

    this._removeAudio().pipe(finalize(() => {
      this.isLoading.set(false)
      this._changeDetectorRef.detectChanges()
    })).subscribe();
  }

  public onResetAudio() {
    this.showError = false
  }

  public onAIAudioGenerationClicked() {
    this.isVoiceSettingChanged = false;
    if (this.isLoading()) {
      return
    }

    if (this.isVoiceSettingChanged && this.audioUrl()) {
      this.regenerateAudio();
      return;
    }

    this.generateAudio.next();
  }

  public onToggleVoiceSettings() {
    this.isVoiceSettingsOpened = !this.isVoiceSettingsOpened;
  }

  public onVoiceSettingsUpdated(settings: IPodcastVoiceSettings) {
    const updatedConfig: Partial<IPodcastVoiceConfig> = {
      ...(this.projectKeyConfig?.config?.voice_settings || {}),
      voice_settings: settings
    }

    this._configUpdateEvents.next(updatedConfig);
  }

  public onSelectVoice(id: string) {
    this._updateVoiceSettings({voice_id: id});
  }

  private _updateVoiceSettings(config: Partial<IPodcastVoiceConfig>) {
    this.projectKeyConfig = {
      ...this.projectKeyConfig,
      config: {
        ...(this.projectKeyConfig.config || {}),
        ...config
      } as IPodcastVoiceConfig
    }
    this._changeDetectorRef.detectChanges();

    this._projectKeyService.updateKeyConfig(this.projectKeyConfig).subscribe(() => {
      this.isVoiceSettingChanged = !!this.audioUrl();
      this._changeDetectorRef.detectChanges();
    });
  }

  private _initVoiceConfig() {
    this.projectKeyConfig = this._projectKeyService.findConfig(
      this.translationKey, this.language, this._projectsService.project$.value.projectId
    );
    if (!this.projectKeyConfig.config?.voice_id) {
      this.projectKeyConfig = {
        ...this.projectKeyConfig,
        config: {
          ...(this.projectKeyConfig.config || {}),
          voice_id: this._podcastService.voices$.value[0]?.voice_id,
        }
      }
    }
  }

  private _removeAudio() {
    return this._draftAssetService.removeItem(this.translationKey).pipe(
      tap(() => {
        this.isAudioRecorderEnabled.set(false);
        this.audioUrl.set(null);
      }),
      catchError((error) => {
        alert(error.message)
        // this.showError = true
        throw error
      }),
    );
  }

  private regenerateAudio() {
    this._removeAudio()
      .pipe(finalize(() => {
        this.generateAudio.emit();
        this.isLoading.set(false);
        this._changeDetectorRef.detectChanges();
      })).subscribe();
  }
}
