<div class="dropdown-wrapper" [ngClass]="isDropdownOpened ? 'z-10' : 'z-0'">
  <div
    class="select"
    [ngClass]="isDisabled && 'pointer-events-none opacity-70 !w-fit'"
    (click)="open(dropdown, originSelect)"
    #originSelect
  >
    <ng-container
      [ngTemplateOutlet]="dropdownItemTpl"
      [ngTemplateOutletContext]="{ dataSource: originalSelectedOption, isDefault: true }"
    >
    </ng-container>
  </div>
</div>

<ng-template #dropdown>
  <div class="select-menu">
    <cdk-virtual-scroll-viewport
      [itemSize]="maxSize < originalDataSource?.length ? maxSize : (placeholder ? originalDataSource?.length : originalDataSource?.length - 1)"
      [style.height.px]="(maxSize < originalDataSource?.length ? maxSize : (placeholder ? originalDataSource?.length : originalDataSource?.length - 1)) * 44"
    >
      <ng-container *cdkVirtualFor="let item of originalDataSource | excludeOption : this.originalSelectedOption : placeholder; index as index">
        <ng-container [ngTemplateOutlet]="dropdownItemTpl" [ngTemplateOutletContext]="{ dataSource: item, index: index }">
        </ng-container>
      </ng-container>
    </cdk-virtual-scroll-viewport>
  </div>
</ng-template>

<ng-template #dropdownItemTpl let-dataSource="dataSource" let-index="index" let-isDefault="isDefault">
  <div (click)="select(dataSource)" class="select-item dropdown__language-base clickable hover-scale-none w-full">
    <div class="language-wrapper w-full">
      <svg-icon [src]="prefixIcon" *ngIf="prefixIcon && isDefault" [svgStyle]="{ 'width.px': 16, 'height.px': 16 }" />

      <div
        class="name text--short-md--semibold dropdown__language-base--text clamp--1"
        [class.disabled]="isDisabled"
        [ngClass]="isDefault ? 'w-[90%]' : 'w-full'"
      >
        {{ dataSource | optionDisplay : translationObject : placeholder }}
      </div>

      <div class="toggle flex items-center justify-end" [class.rotate]="!!popperRef" *ngIf="isDefault && !isDisabled">
        <mat-icon class="icon clickable !h-4" [svgIcon]="ICON_NAMES.ChevronDown"></mat-icon>
      </div>
    </div>
  </div>
</ng-template>
