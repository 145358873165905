import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'app-toggle-voice-settings',
  standalone: true,
  imports: [
    SvgIconComponent,
    TranslateModule,
    NgIf,
    NgClass,
  ],
  templateUrl: './toggle-voice-settings.component.html',
  styleUrl: './toggle-voice-settings.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleVoiceSettingsComponent {
  @Input() isVoiceSettingsOpened: boolean;
  @Output() toggleVoiceSettings = new EventEmitter<void>();
}
